<template>
  <div class="MemberReceiver">
    <div class="top">
      <div class="addbtn" @click="add">
        <span>{{ $t("Cart.Checkout.AddNewAddress") }}</span>
      </div>
      <p>
        {{ $t("Member.Common.HaveCreate")
        }}<span class="addrNum"> {{ allAddr.length }} </span
        >{{ $t("Member.Common.ShippingAddress") }}
        <!-- ，最多可创建<span class="max_num">25</span>个  -->
      </p>
    </div>

    <div class="addrList">
      <div class="item" v-for="item in allAddr" :key="item.addr_id">
        <div class="title">
          <div class="left">
            <h3 class="name">{{ item.ship_name }}</h3>
            <span class="set"><i class="iconfont icon-wrench-full"></i></span>
            <div class="isDeftag" v-if="item.default_addr == '1'">
              {{ $t("Common.Pages.Default") }}
            </div>

            <!-- 点击设置可以去修改地址的别名 -->
            <div class="hidden"></div>
          </div>

          <div class="right">
            <span @click="del(item.addr_id)"
              ><i class="iconfont icon-guanbi"></i
            ></span>
          </div>
        </div>

        <div class="content">
          <div class="left_cont">
            <div class="cont_item">
              <span class="key">{{ $t("User.Address.Name") }}：</span>
              <span class="value">{{ item.ship_name }}</span>
            </div>
            <div class="cont_item">
              <span class="key">{{ $t("User.Address.Postcode") }}：</span>
              <span class="value">{{ item.ship_zip }}</span>
            </div>
            <div class="cont_item">
              <span class="key">{{ $t("User.Address.Addr") }}：</span>
              <span class="value">{{ item.ship_addr }}</span>
            </div>
            <div class="cont_item">
              <span class="key">{{ $t("User.Address.Mobile") }}：</span>
              <span class="value">{{ item.ship_mobile }}</span>
            </div>
          </div>

          <div class="right_cont">
            <div class="setDefBtn" v-if="item.default_addr == 0">
              <span class="def" @click="setDef(item.addr_id, item)">{{
                $t("User.Address.SetDefault")
              }}</span>
            </div>
            <div class="editBtn">
              <span class="edit" @click="edit(item.addr_id, item)">{{
                $t("Common.Button.Edit")
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="noAddr" v-if="allAddr.length == 0">
      <empty
        :decripe="$t('Cart.Checkout.Msg.NoAddress')"
        :isloading="isloading"
      ></empty>
    </div>

    <!-- 弹框 -->
    <pop-up :isshow="isshowDialog" width="70vw">
      <div class="pop">
        <div class="header">
          <span>{{ popname }}</span>
          <i class="iconfont icon-guanbi close" @click="close"></i>
        </div>

        <div class="content_f">
          <!-- 用户名 -->
          <div class="addrItem">
            <div class="left">
              <span class="name"
                ><strong>*</strong>{{ $t("User.Address.Name") }}：</span
              >
            </div>
            <div class="right">
              <has-icon-input
                :height="50"
                backgroundColor="#f6f6f6"
                class="input"
                v-model="userInfo.name"
              ></has-icon-input>
            </div>
          </div>
          <!-- 用户手机号 -->
          <div class="addrItem">
            <div class="left">
              <span class="name"
                ><strong>*</strong>{{ $t("User.Address.Mobile") }}：</span
              >
            </div>
            <div class="right">
              <has-icon-input
                :height="50"
                backgroundColor="#f6f6f6"
                class="input"
                v-model="userInfo.mobile"
              ></has-icon-input>
            </div>
          </div>

          <!-- 城市选择器 -->
          <city-selection
            @changeCity="changeCity"
            @changeShipAreaCode="changeShipAreaCode"
            :defaultChoose="defaultChoose"
          />

          <!-- 用户省份 -->
          <div class="addrItem">
            <div class="left">
              <span class="name"
                ><strong>*</strong>{{ $t("User.Address.Province") }}：</span
              >
            </div>
            <div class="right">
              <has-icon-input
                :height="50"
                backgroundColor="#f6f6f6"
                class="input"
                v-model="userInfo.ship_province"
              ></has-icon-input>
            </div>
          </div>

          <!-- 用户地址 -->
          <div class="addrItem">
            <div class="left">
              <span class="name"
                ><strong>*</strong>{{ $t("User.Address.Addr") }}：</span
              >
            </div>
            <div class="right">
              <has-icon-input
                :height="50"
                backgroundColor="#f6f6f6"
                class="input"
                v-model="userInfo.site"
              ></has-icon-input>
            </div>
          </div>
          <!-- 用户地址2 -->
          <div class="addrItem">
            <div class="left">
              <span class="name"
                ><strong></strong>{{ $t("User.Address.Addr2") }}：</span
              >
            </div>
            <div class="right">
              <has-icon-input
                :height="50"
                backgroundColor="#f6f6f6"
                class="input"
                v-model="userInfo.ship_addr_2"
              ></has-icon-input>
            </div>
          </div>
          <!-- 邮编 -->
          <div class="addrItem">
            <div class="left">
              <span class="name"
                ><strong>*</strong>{{ $t("User.Address.Postcode") }}：</span
              >
            </div>
            <div class="right">
              <has-icon-input
                :height="50"
                backgroundColor="#f6f6f6"
                class="input"
                v-model="userInfo.postcode"
              ></has-icon-input>
            </div>
          </div>
        </div>

        <div class="btn">
          <span class="userInfo" @click="saveOrEdit">{{
            $t("Common.Button.Save")
          }}</span>
        </div>
      </div>
    </pop-up>
  </div>
</template>
<script>
import PopUp from "common/popup/PopUp";
import HasIconInput from "common/input/HasIconInput";
import Empty from "common/empty/Empty";
import User from "@/model/User.js";
export default {
  name: "MemberReceiver",
  data() {
    return {
      //收货人信息
      userInfo: {
        name: "",
        postcode: "",
        site: "",
        ship_addr_2: "",
        mobile: "",
        ship_area: "",
        ship_area_code: "",
        ship_province: "",
      },

      //是否显示弹框
      isshowDialog: false,

      //弹框的文字
      popname: this.$t("Cart.Checkout.ConsigneeInfo"),

      //所有的地址信息
      allAddr: [],

      //当前正在进行的操作 add edit
      action: "add",

      //当前正在进行编辑的地址id
      addr_id: "",

      //当前正在编辑的地址详细信息
      addrDetil: {},

      //当前是否正在加载
      isloading: false,
    };
  },

  computed: {
    defaultChoose() {
      let tem = this.userInfo.ship_area;
      if (tem) {
        return tem.split("-");
      }
    },
  },

  created() {
    this.getAllAddr();
  },
  methods: {
    // 请求地址列表
    getAllAddr() {
      this.allAddr = [];
      this.isloading = true;
      User.Addr.List({
        data: {},
        succ: (res) => {
          this.allAddr = res.address;
          this.isloading = false;
        },
        fail: (res, all) => {
          this.$message("error", all.msg);
          this.isloading = false;
        },
      });
    },

    //点击关闭
    close() {
      this.isshowDialog = false;
      this.reset();
    },

    //增加地址
    add() {
      this.isshowDialog = true;
      this.popname =
        this.$t("Common.Button.Add") +
        " " +
        this.$t("Cart.Checkout.ConsigneeInfo");
      this.action = "add";
    },

    // 改变了城市的选项
    changeCity(e) {
      this.userInfo.ship_area = e.join("-");
    },

    changeShipAreaCode(code) {
      this.userInfo.ship_area_code = code;
    },

    //检查输入框内是否有内容为空
    isEmpty(obj) {
      let filter = ["ship_area_code", "ship_addr_2"];
      let res = false;
      Object.keys(obj).forEach((item) => {
        if (filter.includes(item)) {
          return;
        }
        if (["", undefined].includes(obj[item])) {
          res = true;
        }
      });
      return res;
    },

    //设为默认
    setDef(addr_id, addr) {
      this.$confirm(
        "danger",
        this.$t("Common.Confirm.Title"),
        this.$t("User.Address.Msg.SetDefAddr"),
        true,
        null,
        this
      )
        .then((ele) => {
          User.Addr.setDefAddr({
            data: {
              addr_id: addr_id,
            },
            succ: (res, all) => {
              ele.close();
              this.getAllAddr();
              this.$message("success", all.msg);
            },
            fail: (res, all) => {
              ele.close();
              this.$message("error", all.msg);
            },
          });
        })
        .catch(() => {});
    },

    // 删除地址
    del(addr_id) {
      this.$confirm(
        "danger",
        this.$t("Common.Confirm.Title"),
        this.$t("User.Address.Msg.DeleteAddr"),
        true,
        null,
        this
      )
        .then((ele) => {
          User.Addr.Delete({
            data: {
              addr_id,
            },
            succ: (res, all) => {
              ele.close();
              this.getAllAddr();
              this.$message("success", all.msg);
            },
            fail: (res, all) => {
              ele.close();
              this.$message("error", all.msg);
            },
          });
        })
        .catch(() => {});
    },

    //弹框中点击了保存按钮
    saveOrEdit() {
      let addr_id;
      let default_addr;

      if (this.isEmpty(this.userInfo)) {
        return this.$message("error", this.$t("Passport.Validation.isEmpty"));
      }
      if (this.action == "add") {
        addr_id = 0;

        //如果之前没有添加过地址则将第一个地址转为默认地址
        this.allAddr.length == 0 ? (default_addr = 1) : (default_addr = 0);
      } else if (this.action == "edit") {
        addr_id = this.addr_id;
        default_addr = this.addrDetil.default_addr;
      }

      User.Addr.Save({
        data: {
          action: this.action,
          addr_id: addr_id,
          ship_area: this.userInfo.ship_area,
          ship_area_code: this.userInfo.ship_area_code,
          ship_province: this.userInfo.ship_province,
          ship_addr: this.userInfo.site,
          ship_addr_2: this.userInfo.ship_addr_2,
          ship_name: this.userInfo.name,
          ship_mobile: this.userInfo.mobile,
          ship_zip: this.userInfo.postcode,
          default_addr: default_addr,
          city: "",
          province:"",
        },
        succ: (res, all) => {
          this.$message("success", all.msg);
          this.close();
          this.getAllAddr();
        },
        fail: (res, all) => {
          this.$message("error", all.msg);
        },
      });
    },

    //重置地址信息
    reset() {
      this.userInfo = {
        name: "",
        postcode: "",
        site: "",
        ship_addr_2: "",
        mobile: "",
        ship_area: "",
        ship_area_code: "",
        ship_province: "",
      };
      this.addrDetil = {};
    },

    //编辑地址
    edit(addr_id, item) {
      this.isshowDialog = true;
      this.popname =
        this.$t("Common.Button.Edit") +
        " " +
        this.$t("Cart.Checkout.ConsigneeInfo");
      this.action = "edit";
      this.addr_id = addr_id;
      this.addrDetil = item;

      this.userInfo.name = item.ship_name || '';
      this.userInfo.postcode = item.ship_zip || '';
      this.userInfo.site = item.ship_addr || '';
      this.userInfo.ship_addr_2 = item.ship_addr_2 || '';
      this.userInfo.mobile = item.ship_mobile || '';
      this.userInfo.ship_area = item.ship_area || '';
      this.userInfo.ship_area_code = item.ship_area_code || '';
      this.userInfo.ship_province = item.ship_province || '';
    },
  },
  components: {
    PopUp,
    HasIconInput,
    Empty,
    CitySelection: () => import("content/citySelection"),
  },
  props: {},
};
</script>
<style lang='scss' scoped>
.MemberReceiver {
  padding: 1rem;
  background-color: white;
  box-sizing: border-box;

  .top {
    display: flex;
    align-items: center;
    color: var(--text-color-default);
    font-size: 0.75rem;
    .addbtn {
      width: 10rem;
      height: calc(30rem / 16);
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.875rem;
      cursor: pointer;
      font-weight: 600;
      color: var(--text-color-title);
      border: 1px solid #bfd6af;
      background-color: #f0f9e9;
      margin-right: 0.75rem;
    }

    .addrNum,
    .max_num {
      color: #bfd6af;
    }
  }

  .addrList {
    .item {
      margin-top: 1rem;
      box-sizing: border-box;
      padding: 0.75rem;
      border: 2px solid var(--border-color-default);
      .title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
        .left {
          font-size: 0.875rem;
          color: var(--text-color-title);
          display: flex;
          align-items: center;

          .set {
            margin: 0 1rem;
            font-size: 0.75rem;
          }

          .isDeftag {
            padding: 0.2rem 0.2rem;
            background-color: #ffaa45;
            color: white;
            font-size: 0.75rem;
          }
        }

        .right {
          span {
            cursor: pointer;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }

      .content {
        font-size: 0.75rem;
        display: flex;
        .left_cont {
          max-width: 70%;
          width: 100%;
          .cont_item {
            margin-bottom: 0.75rem;
            display: flex;
            // flex-wrap: wrap;
            .key {
              // flex:none;
              color: var(--text-color-default);
              display: block;
              text-align: right;
              width: 110px;
              margin-right: 0.35rem;
              line-height: 1.5;
            }

            .value {
              // flex:none;
              line-height: 1.5;
              width: calc(100% - 110px - 0.35rem);
              color: var(--text-color-title);
            }
          }
        }

        .right_cont {
          max-width: 30%;
          width: 100%;
          position: relative;
          .setDefBtn {
            position: absolute;
            bottom: 0.75rem;
            right: 3.5rem;
            .def {
              color: #005ea7;
              cursor: pointer;
              &:hover {
                opacity: 0.8;
              }
            }
          }
          .editBtn {
            position: absolute;
            bottom: 0.75rem;
            right: 1rem;
            .edit {
              color: #005ea7;
              cursor: pointer;
              &:hover {
                opacity: 0.8;
              }
            }
          }
        }
      }
    }
  }

  .pop {
    // width: 100%;
    .header {
      width: 45rem;
      background-color: #f3f3f3;
      display: flex;
      justify-content: space-between;
      color: var(--text-color-minor);
      font-size: 0.875rem;
      padding: 0.8rem 0.5rem;
      align-items: center;
      i {
        cursor: pointer;
      }
    }

    .content_f {
      padding: 1rem;
      background-color: white;
      .addrItem {
        display: flex;
        padding: 0.5rem;
        .left {
          width: 110px;
          margin-right: 12px;
          .name {
            line-height: 50px;
            height: 50px;
            display: block;
            // margin-right: 1rem;
            // margin-bottom: 1rem;
            font-size: 0.75rem;
            color: var(--text-color-minor);
            strong {
              color: var(--red-color);
              margin-right: 3px;
            }
          }
        }
        .right {
          flex-grow: 1;
          .input {
            flex-grow: 1;
            padding: 0;
          }
        }
      }
    }

    .btn {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 1rem;
      padding-right: 1rem;
      span {
        background-color: var(--red-color);
        color: white;
        font-size: 0.75rem;
        padding: 0.5rem 1rem;
        border-radius: 3px;
        cursor: pointer;
        &:hover {
          opacity: 0.9;
        }
      }

      .setMore {
        margin-right: 0.75rem;
        background-color: white;
        border: 1px solid var(--border-color-default);
        color: var(--text-color-default);
        box-sizing: border-box;
      }
    }
  }
}
</style>